<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col class="mt-2" cols="6" sm="5" md="3" lg="3" xl="2">
          <v-text-field
            outlined
            dense
            v-model="desdeFecha"
            label="Desde"
            type="date"
            class="mr-2 mb-n4"
          ></v-text-field>
        </v-col>

        <v-col class="mt-2" cols="6" sm="5" md="3" lg="3" xl="2">
          <v-text-field
            outlined
            dense
            v-model="hastaFecha"
            label="Hasta"
            type="date"
            class="mb-n4"
          ></v-text-field>
        </v-col>
      </v-row>

      <template v-if="$store.state.esDepartamento">
        <v-row no-gutters class="mt-2">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <DirFind label="Tienda" ref="dirCtrl" class="mr-2 mb-n4" v-model="dir" @change="cargar"></DirFind>
            </v-col>

          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <PerFind label="Empleado" ref="perCtrl" class="mb-n4" v-model="per"></PerFind>
          </v-col>
        </v-row>
      </template>

      <v-row justify="center">
        <v-col cols="6" md="3" lg="2" xl="2">
          <v-btn
            color="primary"
            @click="procFichajes('HTML')"
            block
            >
              Ver
          </v-btn>
        </v-col>
        <v-col cols="6" md="3" lg="2" xl="2">
          <v-btn
            color="blue-grey"
            class="pl-0 pr-0 white--text"
            @click="procFichajes('PDF')"
            block
            >
            <v-icon style="width:20px" class="mr-2">mdi-adobe-acrobat</v-icon>
            Descargar
          </v-btn>
        </v-col>        
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="fichajes"
            :options.sync="options"
            :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
            :server-items-length="totalFichajes"
            :loading="loading"
            style="overflow: scroll"
            mobile-breakpoint="0"
            class="elevation-1">
          </v-data-table>          
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  import DirFind from "../components/DirFind.vue";
  import PerFind from "../components/PerFind.vue";
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
      return {
        loading: false,
        desdeFecha: null,
        hastaFecha: null,
        dir: {},
        per: {},
        fichajes: [],
        options: {itemsPerPage: 50},
        totalFichajes: 0,
        reportFileName: "",
          // { text: 'DEPENDIENTA', value: 'AccNom', class:'col_dpta' },
        headers: [
          { text: 'DIRECCIÓN', value: 'Dir.DirNom', width: "200" },
          { text: 'ACCION', value: 'AccCat.AccCatParent.AccCatNom', width: "80" },
          { text: 'EMPLEADO', value: 'PerNom', width: "200" },
          { text: 'FECHA', value: 'AccFecha', width: "100" },
          { text: 'HORA', value: 'AccHora', width: "100" },
          { text: 'TIPO', value: 'AccTip.AccTipNom', width: "120" },
          { text: 'MOTIVO', value: 'AccCat.AccCatNom', width: "180" },
        ]
      }
    },
    components:{
      DirFind,
      PerFind
    },
    watch: {
        options: {
          handler () {
            if (this.desdeFecha != null)
            {
            this.procesarFichajes()
              .then(data => {
                this.fichajes = data.items
                this.totalFichajes =  data.total
                this.reportFileName = data.fileName
              })
            }
          },
          deep: true,
        },
      },

    mounted() {
      this.dir = this.$store.state.dir;
      if (this.$store.state.esEmpleado) {
        this.per = this.$store.state.per;
      }
      // if (this.$store.state.esDir) {
      //   // this.itemsDir.push(this.$store.state.dir);
      //   this.dir = this.$store.state.dir;
      // }

      // if (!this.$store.state.esDir) {
      //   // this.itemsPer.push(this.$store.state.per);
      //   this.per = this.$store.state.per;
      // }
    },
    methods: {
      procFichajes(Destino){
        // this.destinoInfo = Destino
        this.procesarFichajes(Destino)
          .then(data => {
            if (Destino == "HTML")
            {
              this.fichajes = data.items
              this.totalFichajes = data.total
            }
            else if (Destino == "PDF")
            {
              this.reportFileName = data.fileName
              this.descargarPDF();
            }
          })
      },
      procesarFichajes(Destino){
        return new Promise((resolve) => {
          var ActionVar;
          var NumRegsPag;
          var NumPag;
          if (Destino == "HTML" || Destino == null || Destino == ""){
            ActionVar = "GET_DATA_LIST_PAGINATED";
            NumRegsPag = this.options.itemsPerPage;
            NumPag = this.options.page;
          }
          else if (Destino == "PDF"){
            ActionVar  = "GET_DATA_LIST_REPORT";
            NumRegsPag = null;
            NumPag = null;
          }

          let dirId = null;
          if (this.dir != null) {
            dirId = this.dir.DirId;
          }
          let perId = null;
          if (this.per != null) {
            perId = this.per.PerId;
          }

          var controllerParameters = {
                    Action: ActionVar, // "GET_DATA_LIST_REPORT", // "GET_DATA_LIST_PAGINATED",
                    desdeFecha: this.desdeFecha,
                    hastaFecha: this.hastaFecha,
                    DirId: dirId,
                    PerId: perId,
                    CampoBusqueda: "",
                    ValorBuscar: "",
                    NumRegsPag: NumRegsPag,
                    NumPag: NumPag,
                    EmpId: this.empId,
                    AccCatIdParent: 1,
                    UsrLogin: this.usuario,
                    CampoOrder: "AccTmp DESC"
          } ;       
          var AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/acc", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {

              this.loading = true;
              let items = result.data.EntsList;
              const total = result.data.EntsTotCount;
              const fileName = result.data.FicDst;

            setTimeout(() => {
              this.loading = false
              if (Destino == "HTML")
              {
                resolve({
                  items,
                  total,
                  fileName,
                })
              }
              else
              {
                resolve({
                  items,
                  total,
                  fileName,
                })
              }

            }, 1000)          

          })});
      },
      descargarPDF () {
          var descarga = document.createElement("a");
          descarga.href = this.urlRaiz + "/Files/" + this.reportFileName ;
          // descarga.href = "/Files/" + this.reportFileName ;
          descarga.target = "_blank";
          descarga.download = "FichajesTienda";
          descarga.style.display="none";

//          descarga.click();

          var clickEvent = new MouseEvent('click', { 'view':window, 'bubbles': true, 'cancelable': true });
          descarga.dispatchEvent(clickEvent);
      },
      compararArrayCuadrantes (a, b) {
        if (a.Dependienta > b.Dependienta) {
          return 1;
        }
        if (a.Dependienta < b.Dependienta) {
          return -1;
        }
        // a must be equal to b
        return 0;
      }
  },
}
</script>
<style>
  .col_dpta {
    min-width: 250px;
  }
</style>